import React from 'react'
import {
    createUserWithEmailAndPassword,
    signOut,
    sendPasswordResetEmail
  } from "firebase/auth";
import { useState, useEffect } from 'react'
import { auth, auth2, registerNewUser, } from '../../firebase/firebase'
import { ModalGraficaTemperatura } from './ModalGraficaTemperatura'
import { useModalGraficaTemperatura } from './UseModalGraficaTemperatura'
import { LinesChartTemperatura } from './LinesChartTemperatura'
import { LinesChartHumedad } from '../modalGraficaHumedad/LinesChartHumedad'
import { Button, Table } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { Input } from 'reactstrap';

export function ModalsGraficaTemperatura(dispositivo) {
    const navigate = useNavigate();
    const [isOpenModal, openModal, closeModal] = useModalGraficaTemperatura(false)

    const [añoSelect, setAñoSelect] = useState(new Date().getFullYear())
    const [mesSelect, setMesSelect] = useState(formatMes(new Date))

    const [mostrarGT, setMostrarGT] = useState(false)
    const [mostrarGH, setMostrarGH] = useState(false)

    useEffect(() => {
        
    }, [])

    
    function formatMes(date) {
        let mes = date.getMonth() + 1;
        if (mes < 10) mes = "0" + mes;

        return `${mes}`;
    }

    function onChangeAño (e) {
        // console.log(e.target.value)
        setAñoSelect(e.target.value)
        setMostrarGT(false)
        setMostrarGH(false)
    }

    function onChangeMes (e) {
        // console.log(e.target.value)
        setMesSelect(e.target.value)
        setMostrarGT(false)
        setMostrarGH(false)
    }

    function onClickTemperatura() {
        setMostrarGH(false)
        setMostrarGT(true)
    }

    function onClickHumedad() {
        setMostrarGT(false)
        setMostrarGH(true)
    }

    // console.log(dispositivo);
    return (
        <>
            <Button className='mt-1 mx-1' onClick={openModal}>Histórico</Button>
            <ModalGraficaTemperatura isOpen={isOpenModal} closeModal={closeModal}>
            <h5 className="py-3"><b>Datos historicos</b></h5>
                <div className="mb-3">
                    Año:&nbsp;
                    <select onChange={onChangeAño} defaultValue={new Date().getFullYear()}>
                        <option key="2023" value="2023">2023</option>
                        <option key="2024" value="2024">2024</option>
                        <option key="2025" value="2025">2025</option>
                        <option key="2026" value="2026">2026</option>
                        <option key="2027" value="2027">2027</option>
                        <option key="2028" value="2028">2028</option>
                        <option key="2029" value="2029">2029</option>
                        <option key="2030" value="2030">2030</option>
                    </select>
                    &nbsp;

                    Mes:&nbsp;
                    <select onChange={onChangeMes} defaultValue={formatMes(new Date)}>
                        <option key="01" value="01">Enero</option>
                        <option key="02" value="02">Febrero</option>
                        <option key="03" value="03">Marzo</option>
                        <option key="04" value="04">Abril</option>
                        <option key="05" value="05">Mayo</option>
                        <option key="06" value="06">Junio</option>
                        <option key="07" value="07">Julio</option>
                        <option key="08" value="08">Agosto</option>
                        <option key="09" value="09">Septiembre</option>
                        <option key="10" value="10">Octubre</option>
                        <option key="11" value="11">Noviembre</option>
                        <option key="12" value="12">Diciembre</option>
                    </select>
                    &nbsp;&nbsp;
                    <Button className="mx-1 btn-danger" onClick={onClickTemperatura}>Temperatura</Button>
                    <Button className="mx-1 btn-info" onClick={onClickHumedad}>Humedad</Button>
                </div>
                <LinesChartTemperatura mes={mesSelect} año={añoSelect} mostrar={mostrarGT} dispositivo={dispositivo} />
                <LinesChartHumedad mes={mesSelect} año={añoSelect} mostrar={mostrarGH} dispositivo={dispositivo} />
            </ModalGraficaTemperatura>
        </>
    )
}
