import logo from './logo.svg';
import './App.css';
import { Routes, Route, HashRouter } from "react-router-dom";
import { Login } from "./routes/Login"
import { Admin } from "./routes/Admin"
import { Main } from "./routes/Main"
import { Sobre } from './routes/Sobre';

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Main />}/>
        <Route path="admin" element={<Admin />}/>
        <Route path="login" element={<Login />}/>
        <Route path="proyecto" element={<Sobre />}/>
        {/* <Route path="register" element={<Register/>}/> */}
        <Route path="*" element={<div>Error 404 page not found</div>}/>
      </Routes>
    </HashRouter>
  );
}

export default App;
