import { Button } from "react-bootstrap"
import "../../css/style.css"

export const ModalRegistrarTrabajador = ({children, isOpen, closeModal}) => {

  const handleModalContainerClick = e => e.stopPropagation()
  
  return (
    <article className={`modal-registrar-usuario ${isOpen && "is-open"}`} onClick={closeModal}>
        <div className="modal-container-registrar-usuario" onClick={handleModalContainerClick}>
            <Button variant="transparent" className="modal-close-registrar-usuario" onClick={closeModal}>X</Button>
            {children}
        </div>
    </article>
  )
}