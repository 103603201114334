import React from 'react'
import { useState, useEffect } from "react";
import { ModalProgramarOnOff } from './ModalProgramarOnOff'
import { UseModalProgramarOnOff } from './UseModalProgramarOnOff'
import { Button, Table } from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { Input } from 'reactstrap';

export function ModalsProgramarOnOff({sensor, zona}) {
    const navigate = useNavigate();
    const [isOpenModal, openModal, closeModal] = UseModalProgramarOnOff(false)
    const [fechaSeleccionada, setFechaSeleccionada] = useState(new Date())
    const [encendido, setEncendido] = useState(new Date())

    function onClickAceptar() {
        if (zona !== undefined) {
            if (encendido) alert(`Programado el encendido de la zona ${zona.id} (${zona.nombre}) el día ${formatDate(fechaSeleccionada)} correctamente`)
            else alert(`Programado el apagado de la zona ${zona.id} (${zona.nombre}) el día ${formatDate(fechaSeleccionada)} correctamente`)
        }
        else if (sensor !== undefined) {
            if (encendido) alert(`Programado el encendido del dispositivo ${sensor.id} el día ${formatDate(fechaSeleccionada)} correctamente`)
            else alert(`Programado el apagado del dispositivo ${sensor.id} el día ${formatDate(fechaSeleccionada)} correctamente`)
        }
        
        closeModal()
    }

    function formatDate(date) {
        const day = date.getDate()
        const month = date.getMonth() + 1
        const year = date.getFullYear()

        if(month < 10) return(`${day}/0${month}/${year}`)
        else return(`${day}/${month}/${year}`)
    }

    function onChangeFecha(e) {
        setFechaSeleccionada(new Date(e))
    }

    function onChangeEncender(e) {
        if (e.target.value === "true") setEncendido(false)
        if (e.target.value === "false") setEncendido(true)
    }

    function onChangeApagar(e) {
        if (e.target.value === "true") setEncendido(true)
        if (e.target.value === "false") setEncendido(false)
    }

    if (sensor !== undefined) {
        return (
            <>
                <Button className="mt-1 mx-1 btn-secondary" onClick={openModal}>Programar on/off</Button>
                <ModalProgramarOnOff isOpen={isOpenModal} closeModal={closeModal}>
                    <h2><b>Programar on/off</b></h2>
                    <hr />
                    <h3 className="p-5 mb-3">Dispositivo {sensor.id}</h3>
                    <hr />
                    <h3 className="">Encender/Apagar</h3>
                    <Input className='mb-3' type="checkbox" checked={encendido} value={encendido} onChange={onChangeEncender}/>&nbsp;Encender&nbsp;&nbsp;&nbsp;&nbsp;
                    <Input className='mb-3' type="checkbox" checked={!encendido} value={!encendido} onChange={onChangeApagar}/>&nbsp;Apagar&nbsp;&nbsp;&nbsp;&nbsp;
                    <hr />
                    <h3 className="mb-3">Fecha</h3>
                    <DatePicker selected={fechaSeleccionada} onChange={onChangeFecha}/>
                    <br />
                    <br />
                    <hr />
                    <div className='text-right'>
                        <Button className="btn-danger mr-1" onClick={closeModal}>Cancelar</Button>
                        <Button onClick={onClickAceptar}>Aceptar</Button>
                    </div>
                    
                </ModalProgramarOnOff>
            </>
        )
    }
    else if (zona !== undefined) {
        return (
            <>
                <Button className="mt-1 mx-1 btn-secondary" onClick={openModal}>Programar on/off</Button>
                <ModalProgramarOnOff isOpen={isOpenModal} closeModal={closeModal}>
                    <h2><b>Programar on/off</b></h2>
                    <hr />
                    <h3 className="p-5 mb-3">Zona {zona.id} ({zona.nombre}) </h3>
                    <hr />
                    <h3 className="">Encender/Apagar</h3>
                    <Input className='mb-3' type="checkbox" checked={encendido} value={encendido} onChange={onChangeEncender}/>&nbsp;Encender&nbsp;&nbsp;&nbsp;&nbsp;
                    <Input className='mb-3' type="checkbox" checked={!encendido} value={!encendido} onChange={onChangeApagar}/>&nbsp;Apagar&nbsp;&nbsp;&nbsp;&nbsp;
                    <hr />
                    <h3 className="mb-3">Fecha</h3>
                    <DatePicker selected={fechaSeleccionada} onChange={onChangeFecha}/>
                    <br />
                    <br />
                    <hr />
                    <div className='text-right'>
                        <Button className="btn-danger mr-1" onClick={closeModal}>Cancelar</Button>
                        <Button onClick={onClickAceptar}>Aceptar</Button>
                    </div>
                    
                </ModalProgramarOnOff>
            </>
        )
    }
    else {return(<></>)}

    
}
