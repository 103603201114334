import React from 'react'
import { Line, getElementsAtEvent } from "react-chartjs-2"
import { useRef, useState, useEffect } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from "chart.js"
import { getHistorialPorAñoyMes } from '../../firebase/firebase';
import { Button } from 'react-bootstrap';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
)

export function LinesChartHumedad({año, mes, mostrar, dispositivo}) {

    const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
    const chartRef = useRef()
    const [historial, setHistorial] = useState(null)
    const [mostrarDia, setMostrarDia] = useState(false)
    const [humedadDia, setHumedadDia] = useState([])
    const [mensajeDia, setMensajeDia] = useState("")
    let desfase = 0
    
    if (dispositivo == undefined || dispositivo.dispositivo == undefined || dispositivo.dispositivo == null || dispositivo.dispositivo == 0) {}
    else {
        if (dispositivo.dispositivo.id === "id01") desfase = 0
        if (dispositivo.dispositivo.id === "id02") desfase = 0.4
        if (dispositivo.dispositivo.id === "id03") desfase = -0.5
        if (dispositivo.dispositivo.id === "id04") desfase = 1.4
        if (dispositivo.dispositivo.id === "id05") desfase = 2.1
        if (dispositivo.dispositivo.id === "id06") desfase = 0.3
        if (dispositivo.dispositivo.id === "id07") desfase = -1.2
        if (dispositivo.dispositivo.id === "id08") desfase = -1.8
        if (dispositivo.dispositivo.id === "id09") desfase = -1.2
        if (dispositivo.dispositivo.id === "id10") desfase = 0.7
        if (dispositivo.dispositivo.id === "id11") desfase = 0.5
        if (dispositivo.dispositivo.id === "id12") desfase = 1.6
    }

    if (mostrar === true && historial === null) {
        buscarHistorial()
    }
    

    useEffect(() => {

      }, []);

    async function buscarHistorial() {
        const historialDB = await getHistorialPorAñoyMes(`${año}-${mes}`)
        setHistorial(historialDB)
    }

    if (mostrar === true && historial !== null) {

        // console.log(historial);

        const diasMes = new Date(año, mes, 0).getDate();
        let arrayAux = []
        let suma = 0
        let mensaje = ""
        let datosHumedad = []
        let arrayHoras = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]

        function formatDia(dia) {
            if (dia < 10) {
                return `0${dia}`
            }
            else {
                return dia
            }
          }
        
          for (let dia = 1; dia <= diasMes; dia++) {
            historial.forEach(element => {
                if (element.fecha === `${año}-${mes}-${formatDia(dia)}`) {
                    arrayAux.push(parseInt(element.humi)+desfase)
                }
            });
            // if (arrayAux.length !== 0) {
                arrayAux.forEach(element => {
                    suma = suma + element
                });
                // console.log({suma});
                const media = suma / arrayAux.length
                suma = 0
                // console.log({media});
                // console.log(arrayAux.length);
                arrayAux = []
                datosHumedad.push(media)
            // }
            // else {
            //     datosHumedad.push(0)
            // }
        }
        // console.log(datosHumedad);
        mensaje = `${meses[parseInt(mes-1)]}-${año}`
        
        const arrayDias = Array.from({ length: diasMes }, (_, index) => index + 1);

        let data = {
            labels: arrayDias,
            datasets: [
                {
                    label: "Humedad",
                    data: datosHumedad,
                    tension: 0.4,
                    fill: true,
                    borderColor: "rgb(132, 150, 255)",
                    backgroundColor: "rgba(132, 150, 255, 0.5)",
                    pointRadius: 5,
                    pointBorderColor: "rgb(132, 150, 255)",
                    pointBackgroundColor: "rgb(132, 150, 255)",
                    
                }
            ]
        }

        let options = {
            scales: {
                y: {
                    min: 0
                }
            }
        }

        const onClick = (event => {
            const elemento = getElementsAtEvent(chartRef.current, event)
            if (elemento[0] !== undefined) {
                // console.log(elemento[0].index+1);
                historial.forEach(element => {
                    if (element.fecha === `${año}-${mes}-${formatDia(elemento[0].index+1)}`) {     
                        humedadDia.push(parseInt(element.humi)+desfase)
                        // console.log(humedadDia);
                    }
                });

                setMensajeDia(`${formatDia(elemento[0].index+1)}-${meses[parseInt(mes-1)]}-${año}`)
                
                setMostrarDia(true)
            }
        })

        function botonAtras() {
            setMostrarDia(false)
            setHumedadDia([])
        }

        if (mostrarDia === false) {
            // console.log("mostrar dia false");
            return (
                <>
                    <div className='m-3'><h4>Porcentaje de humedad de {mensaje}</h4></div>
                    <Line onClick={onClick} data={data} options={options} ref={chartRef} />
                </>
            )
        }
        else if (mostrarDia === true)  {
            data = {
                labels: arrayHoras,
                datasets: [
                    {
                        label: "Humedad",
                        data: humedadDia,
                        tension: 0.4,
                        fill: true,
                        borderColor: "rgb(132, 150, 255)",
                        backgroundColor: "rgba(132, 150, 255, 0.5)",
                        pointRadius: 5,
                        pointBorderColor: "rgb(132, 150, 255)",
                        pointBackgroundColor: "rgb(132, 150, 255)",
                        
                    }
                ]
            }
            // console.log(humedadDia);
            return (
                <>
                    <div className='m-3 h4'>
                        <Button onClick={botonAtras}>Atras</Button>
                        <span className='mx-5'>Porcentaje de humedad del {mensajeDia}
                        </span><span />
                    </div>
                    <Line data={data} options={options} ref={chartRef} />
                </>
            )
        }
    }
    else {
        return <></>
    } 
}
