import { onAuthStateChanged, signOut } from "firebase/auth";
import { useState, useContext, useEffect } from "react";
import { auth, getUser } from "../firebase/firebase";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header"
import Footer from "../components/Footer"
import Container from "../components/Container"
import { GoogleMapComponent } from "../components/GoogleMapComponent";
import { ListaTrabajadores } from "../components/ListaTrabajadores";
import { Proyecto } from "../components/Proyecto";

export function Sobre() {
    const navigate = useNavigate();
    const [userExist, setUserExist] = useState(false)
    const [user, setUser] = useState(null)
  
    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                setUserExist(true)
                const userDB = await getUser(user.uid)
                setUser(userDB)
                if (userDB.admin !== true) {
                    navigate("/")
                }
            } else {
                navigate("/login");
            }
        });
    }, [navigate]);

    if (userExist && user !== null && user.admin === true) {
        return (
            <>
                <div className="bg-color">
                    <div className="container-xl bg-white p-0">
                        <Header />
                        <div className="px-4 pb-5 pt-4">
                            <Proyecto />
                        </div>
                        <Footer />
                    </div>
                </div>  
            </>
        );
    } else {
        return (
            <>Cargando...</>
        );
    }
    
}