import { onAuthStateChanged, signOut } from "firebase/auth";
import { useState, useContext, useEffect } from "react";
import { auth, getUser } from "../firebase/firebase";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header"
import Footer from "../components/Footer"
import Container from "../components/Container"
import { GoogleMapComponent } from "../components/GoogleMapComponent";

export function Main() {
    const navigate = useNavigate();
    const [userExist, setUserExist] = useState(false)
    const [user, setUser] = useState(null)
  
    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                setUserExist(true)
                const userDB = await getUser(user.uid)
                setUser(userDB)
            } else {
                navigate("/login");
            }
        });
    }, [navigate]);

    if (userExist && user !== null) {
        return (
            <>
                <Header />
                <Container user={user} />
                <GoogleMapComponent />
                <Footer />
            </>
        );
    } else {
        return (
            <>Cargando...</>
        );
    }
    
}