// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  query,
  where,
  setDoc,
  deleteDoc,
} from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const auth2 = getAuth(initializeApp(firebaseConfig, "Secondary"));
export const db = getFirestore(app);
export const storage = getStorage(app);

export async function userExists(uid) {
  const docRef = doc(db, "users", uid);
  const res = await getDoc(docRef);
  // console.log(res);
  return res.exists();
}

export async function getUser(uid) {
  const docRef = doc(db, "users", uid);
  const res = await getDoc(docRef);
  // console.log(res)
  return res.data();
}

export async function addHistorial(registro) {
  // console.log("clg addhistorial");
  // console.log(registro);
  try {
    const llamadaAPIRef = collection(db, "historial");
    await setDoc(doc(llamadaAPIRef, `${registro.id}_${registro.fecha}_${registro.hora}`), registro);
  } catch (e) {
    // console.error("Error adding document: ", e);
  }
}

export async function registerNewUser(user) {
  try {
    const usersRef = collection(db, "users");
    await setDoc(doc(usersRef, user.uid), user);
  } catch (e) {
    // console.error("Error adding document: ", e);
  }
}

export async function getSensores() {
  const dispositivos = [];
  try {
    const q = query(collection(db, "sensores"))
    const querySnapshot = await getDocs(q)

    querySnapshot.forEach(doc => {
      dispositivos.push(doc.data())
    })

    return dispositivos;

  } catch (error) {
    // console.error(error)
  }
}

export async function getZonas() {
  const zonas = [];
  try {
    const q = query(collection(db, "zonas"))
    const querySnapshot = await getDocs(q)

    querySnapshot.forEach(doc => {
      zonas.push(doc.data())
    })

    return zonas;

  } catch (error) {
    // console.error(error)
  }
}

export async function getUltimosRegistros() {
  const dispositivos = [];
  try {
    const q = query(collection(db, "ultimos_registros"))
    const querySnapshot = await getDocs(q)

    querySnapshot.forEach(doc => {
      dispositivos.push(doc.data())
    })

    return dispositivos;

  } catch (error) {
    // console.error(error)
  }
}

export async function existeUltimoRegistro(registro) {
  const docRef = doc(db, "ultimos_registros", registro.id);
  const res = await getDoc(docRef);
  // console.log(res)
  return res.exists();
}

export async function setUltimoRegistro(registro) {
  try {
    const diaFestivoRef = collection(db, "ultimos_registros");
    await setDoc(doc(diaFestivoRef, registro.id), registro);
  } catch (e) {
    // console.error("Error adding document: ", e);
  }
}

export async function updateUltimoRegistro(registro) {
  try {
    const res = await updateDoc(
    doc(db, "ultimos_registros", registro.id), registro);
    // console.log(res);
  } catch (e) {
    // console.log("error al updatear horario");
    // console.error(e);
  }
}

export async function updateSensor(sensor) {
  try {
    const res = await updateDoc(
    doc(db, "sensores", sensor.id), sensor);
    // console.log(res);
  } catch (e) {
    // console.log("error al updatear horario");
    // console.error(e);
  }
}

export async function getHistorialPorAñoyMes(añomes) {
  // console.log(añomes);
  const historial = [];
  try {
    const q = query(collection(db, "history"), where("añomes", "==", añomes));
    const querySnapshot = await getDocs(q)

    querySnapshot.forEach(doc => {
      historial.push(doc.data())
    })

    return historial;

  } catch (error) {
    // console.error(error)
  }
}

export async function getSensoresPorZonas(idzona) {
  // console.log(idzona);
  const sensores = [];
  try {
    const q = query(collection(db, "sensores"), where("zona", "==", idzona));
    const querySnapshot = await getDocs(q)

    querySnapshot.forEach(doc => {
      sensores.push(doc.data())
    })

    return sensores;

  } catch (error) {
    // console.error(error)
  }
}

export async function addSensor(sensor) {
  try {
    const llamadaAPIRef = collection(db, "sensores");
    await setDoc(doc(llamadaAPIRef, sensor.id), sensor);
  } catch (e) {
    // console.error("Error adding document: ", e);
  }
}

export async function addZona(zona) {
  // console.log(zona);
  try {
    const llamadaAPIRef = collection(db, "zonas");
    await setDoc(doc(llamadaAPIRef, zona.id), zona);
  } catch (e) {
    // console.error("Error adding document: ", e);
  }
}

export async function getTrabajadores() {
  const trabajadores = [];
  try {
    const q = query(collection(db, "users"));
    const querySnapshot = await getDocs(q)

    querySnapshot.forEach(doc => {
      trabajadores.push(doc.data())
    })

    return trabajadores;

  } catch (error) {
    // console.error(error)
  }
}

export async function getTrabajador(trabajador) {
  const docRef = doc(db, "users", trabajador.uuid);
  const res = await getDoc(docRef);
  // console.log(res)
  return res.data();
}

export async function updateTrabajador(user) {
  try {
    // console.log(user)
    const res = await updateDoc(
      doc(db, "users", user.uuid),
      user
    );
    // console.log(res);
  } catch (e) {
    // console.log("error al updatear trabajadores");
    // console.error(e);
  }
}

export async function addTrabajador(user) {
  try {
    const usersRef = collection(db, "users");
    await setDoc(doc(usersRef, user.uuid), user);
  } catch (e) {
    // console.error("Error adding document: ", e);
  }
}