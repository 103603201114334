import {
    signInWithEmailAndPassword,
    onAuthStateChanged,
    signOut,
    setPersistence,
    browserLocalPersistence,
    sendPasswordResetEmail,
} from "firebase/auth";
import { setIndexConfiguration } from "firebase/firestore";
import { useState, useEffect } from "react";
import { auth, getUser, userExists } from "../firebase/firebase";
import { useNavigate } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { Input } from "reactstrap";
import logoLoRo from "../img/Proyecto-LoRo-3.png"

export function Login() {
  /*
  Stages:
  0: loading initiated
  1: loading
  2: login completed
  3: not logged
  4: reset password
  */

  const [state, setCurrentState] = useState(0);
  const [email, setEmail] = useState("");
  const [emailAux, setEmailAux] = useState("");
  const [password, setPassword] = useState("");
  const [mensajeDeAviso, setMensajeDeAviso] = useState("");
  const [recuerdame, setRecuerdame] = useState(false);
  const [errorLogin, setErrorLogin] = useState("")
  const navigate = useNavigate();

  useEffect(() => {
    setCurrentState(1);
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        navigate("/")
      } else {
        setCurrentState(3);
        // console.log("No hay nadie autenticado ... ");
      }
    });
  }, [navigate]);

  function singIn(e) {
    e.preventDefault();

    if (recuerdame === true) {
      setPersistence(auth, browserLocalPersistence)
      .then(() => {
        // Existing and future Auth states are now persisted in the current
        // session only. Closing the window would clear any existing state even
        // if a user forgets to sign out.
        // ...
        // New sign-in will be persisted with session persistence.
        signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            navigate("/")
          })
          .catch(() => {
            // const errorCode = error.code;
            // const errorMessage = error.message;
            // console.log("error al entrar");
            setErrorLogin("Usuario o contraseña incorrectos")
          });
      })
      .catch((error) => {
        // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
      });
    } else {
      signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            // Signed in
            navigate("/")
          })
          .catch((error) => {
            // const errorCode = error.code;
            // const errorMessage = error.message;
            // console.log("error al entrar");
            setErrorLogin("Usuario o contraseña incorrectos")
          });
    }
  }

  function logout() {
    signOut(auth)
      .then(() => {
        
      })
      .catch((error) => {
        
      });

    //console.log("OUT");
    //auth().logout();
    //window.location.href = "login.html";
  }

  function resetPassword(e) {
    e.preventDefault();
    sendPasswordResetEmail(auth, emailAux)
    .then(() => {
        // console.log("EMAIL TO "+email)
        setMensajeDeAviso("Correo enviado")
        setEmailAux("")
    })
    .catch((error) => {
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // console.log(errorCode)
        // console.log(errorMessage)
        setMensajeDeAviso("Correo enviado")
        setEmailAux("")
    });
  }

  function onClickHeOlvidadoContraseña() {
    setCurrentState(4)
    setEmailAux("")
    setMensajeDeAviso("")
  }

  function onClickCancelar() {
    setEmail("")
    setPassword("")
    setErrorLogin("")
    setCurrentState(3)
  }

  function onChangeRecuerdame(e) {
    if (e.target.value === "true") setRecuerdame(false)
    else if (e.target.value === "false") setRecuerdame(true)
  }

  function onChangeRecuerdame2() {
    if (recuerdame === true) setRecuerdame(false)
    else if (recuerdame === false) setRecuerdame(true)
  }

  if (state === 1) {
    return <>Cargando...</>
  }

  if (state === 3) {
    return (
      <div className="modal-login">
        <div className="modal-container-login">
          <form onSubmit={singIn}>
            <div className="mb-4 text-center">
              <img src={logoLoRo} width="300px" alt="logo_empresa" />
            </div>
            <h5 className="mb-1">Correo electronico</h5><Input
              placeholder="Email"
              className="mb-2"
              onChange={(e) => {
                setEmail(e.target.value);
                setErrorLogin("");
                //console.log(e.target.value);
              }}
              value={email}
              autoFocus
            /><h5 className="mb-1">Contraseña</h5>
            <Input
              type="password"
              placeholder="Contraseña"
              className="mb-2"
              onChange={(e) => {
                setPassword(e.target.value);
                setErrorLogin("");
                //console.log(e.target.value);
              }}
              value={password}
            />
            <p className="text-danger">{errorLogin}</p>
            <div className="mb-1 p-0"> 
              <input type="checkbox" checked={recuerdame} value={recuerdame} onChange={onChangeRecuerdame}/>&nbsp;
              <Button className="p-0 ml-2 mb-1" variant="transparent" onClick={onChangeRecuerdame2}>No cerrar sesion</Button> 
            </div>
            <div>
              <div className="text-center mx-auto">
                <Button className="btn-success px-5 btn btn-block" type="submit">Login</Button>
              </div>
              <div className="text-center">
                <Button variant="transparent" className="py-0 px-1" type="button" onClick={onClickHeOlvidadoContraseña}>He olvidado la contraseña</Button>
              </div>
            </div>

          </form>
        </div>
      </div>
    );
  }

  if (state === 4) {
    return (
      <div className="modal-login">
        <div className="modal-container-login">
        <div className="mb-4 text-center">
          <img src={logoLoRo} width="300px" alt="logo" />
        </div>
        <h5>Correo electronico</h5>
        <form onSubmit={resetPassword}>
            <Input
              placeholder="Email"
              onChange={(e) => {
                setEmailAux(e.target.value);
                setMensajeDeAviso("")
              }}
              value={emailAux}
              autoFocus
            />
          <p>{mensajeDeAviso}</p>
          <div className="row">
            <div className="col">
              <Button onClick={onClickCancelar}>Login</Button>
            </div>
            <div className="col text-right">
              <Button onClick={resetPassword} className="btn-success">Confirmar</Button>
            </div>
          </div>
        </form>
        </div>
      </div>
    );
  }

  return (
    <>Cargando...</>
  );
}