import logo from "../img/Proyecto-LoRo-3.png"
import logoCLM from "../img/logoCLM.png"
import logoUE from "../img/logoUE.png"

export function Proyecto() {

  return(
    <>
        <div className="text-center mb-5">
            <img src={logo} alt="logo" />
        </div>
        <div className="text-justify mx-5">
            <p><b>Proyecto incentivado con una subvención cofinanciada en un 80% por el Fondo Europeo de Desarrollo Regional, en el marco del Programa INNOVA ADELANTE en Castilla-La Mancha en su convocatoria 2022.</b></p>

            <p>Título del proyecto: <b>LoRo (Long Range Objects)</b></p>

            <p>Expediente: <b>45/22/CO/033</b></p>

            <p>Descripción del Proyecto</p>

            <p>LoRo (Long Range Objects): Sistema de Incorporación de Dispositivos para su Monitorización y Gestión mediante tecnología IoT</p>

            <p>El objetivo del Proyecto LoRo es diseñar e implementar un sistema formado por dispositivos que utilizando los nuevos microcontroladores LX6 -de alta eficiencia y bajo consumo- y la tecnología LoRA, que sean capaces de conectarse a cualquier elemento eléctrico o electrónico y capacitarlo para integrarse en una red IoT (Internet of Things) ya sea privada con loRa creada ad hoc, o pública, mediante LoRaWan.</p>
        </div>
        <div className="row mt-5">
            <div className="col-xl-6 text-center"><img src={logoCLM} height={150} width={240} alt="Logo Castilla La Mancha" /></div>
            <div className="col-xl-6 text-center"><img src={logoUE} height={150} width={500} alt="Logo Unión Europea" /></div>
        </div>
    </>
  )
}