import { useState, useEffect, useRef, useMemo } from "react";
import { GoogleMap, LoadScript, Marker, MarkerF, useLoadScript } from "@react-google-maps/api";
import { ModalsGraficaHumedad } from './modalGraficaHumedad/ModalsGraficaHumedad';
import { ModalsGraficaTemperatura } from './modalGraficaTemperatura/ModalsGraficaTemperatura';
import { ModalGraficaHumedad } from "./modalGraficaHumedad/ModalGraficaHumedad";
import { ModalGraficaTemperatura } from "./modalGraficaTemperatura/ModalGraficaTemperatura";
import { useModalGraficaTemperatura } from "./modalGraficaTemperatura/UseModalGraficaTemperatura";
import { useModalGraficaHumedad } from "./modalGraficaHumedad/UseModalGraficaHumedad";
import { Button, Table } from 'react-bootstrap';
import { LinesChartTemperatura } from './modalGraficaTemperatura/LinesChartTemperatura'
import { LinesChartHumedad } from './modalGraficaHumedad/LinesChartHumedad'
import { getSensores } from "../firebase/firebase";

export function GoogleMapComponent() {

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLEMAP_APIKEY
  });

  if (!isLoaded) return <>Cargando...</>
  return <Map />
}

const mapOptions = {
  center: {lat: 39.86912965091455, lng: -3.9923418480294495},
  zoom: 13,
  disableDefaultUI: true,
};

// &amp;q=40,-4

function Map() {
  const [isOpenModalGT, openModalGT, closeModalGT] = useModalGraficaTemperatura(false)

  const [añoSelect, setAñoSelect] = useState(new Date().getFullYear())
  const [mesSelect, setMesSelect] = useState(formatMes(new Date))
  const [mostrarGT, setMostrarGT] = useState(false)
  const [mostrarGH, setMostrarGH] = useState(false)

  const [sensores, setSensores] = useState(null)

  useEffect(() => {
    getAllSensores()
  }, []);

  async function getAllSensores() {
    const sensoresAux = await getSensores()
    setSensores(sensoresAux)
  }

  // return <div className="mx-auto mb-2" style={{width: 1000}}><iframe width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=es&amp;q=espacios%20castellano%20de%20innovacion&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/car-satnav-gps/">Car Navigation Systems</a></iframe></div>
  const center = useMemo(() => ({lat: 39.86912965091455, lng: -3.9923418480294495}), [])

  function formatMes(date) {
    let mes = date.getMonth() + 1;
    if (mes < 10) mes = "0" + mes;

    return `${mes}`;
  }

  function onChangeAño (e) {
    // console.log(e.target.value)
    setAñoSelect(e.target.value)
    setMostrarGT(false)
  }

  function onChangeMes (e) {
    // console.log(e.target.value)
    setMesSelect(e.target.value)
    setMostrarGT(false)
  }

  function onClickTemperatura() {
    setMostrarGH(false)
    setMostrarGT(true)
  }

  function onClickHumedad() {
      setMostrarGT(false)
      setMostrarGH(true)
  }

  if (sensores !== null) {
    return ( 
      <div className="mx-auto mb-4" style={{width: 1000}}>
        <ModalGraficaTemperatura isOpen={isOpenModalGT} closeModal={closeModalGT}>
        <div className="text-center">
          <h5 className="py-3"><b>Datos historicos</b></h5>
            <div className="mb-3">
                Año:&nbsp;
                <select onChange={onChangeAño} defaultValue={new Date().getFullYear()}>
                    <option key="2023" value="2023">2023</option>
                    <option key="2024" value="2024">2024</option>
                    <option key="2025" value="2025">2025</option>
                    <option key="2026" value="2026">2026</option>
                    <option key="2027" value="2027">2027</option>
                    <option key="2028" value="2028">2028</option>
                    <option key="2029" value="2029">2029</option>
                    <option key="2030" value="2030">2030</option>
                </select>
                &nbsp;
  
                Mes:&nbsp;
                <select onChange={onChangeMes} defaultValue={formatMes(new Date)}>
                    <option key="01" value="01">Enero</option>
                    <option key="02" value="02">Febrero</option>
                    <option key="03" value="03">Marzo</option>
                    <option key="04" value="04">Abril</option>
                    <option key="05" value="05">Mayo</option>
                    <option key="06" value="06">Junio</option>
                    <option key="07" value="07">Julio</option>
                    <option key="08" value="08">Agosto</option>
                    <option key="09" value="09">Septiembre</option>
                    <option key="10" value="10">Octubre</option>
                    <option key="11" value="11">Noviembre</option>
                    <option key="12" value="12">Diciembre</option>
                </select>
                &nbsp;&nbsp;
                <Button className="mx-1 btn-danger" onClick={onClickTemperatura}>Temperatura</Button>
                <Button className="mx-1 btn-info" onClick={onClickHumedad}>Humedad</Button>
            </div>
            <LinesChartTemperatura mes={mesSelect} año={añoSelect} mostrar={mostrarGT} />
            <LinesChartHumedad mes={mesSelect} año={añoSelect} mostrar={mostrarGH} />
          </div>
        </ModalGraficaTemperatura>
  
        <GoogleMap 
          zoom={13} 
          center={center}
          mapContainerClassName="map-container"
          mapTypeId="hybrid">
            {sensores.map((dispositivo, index) => (
              <MarkerF
              key={dispositivo.id}
              position={{lat: dispositivo.lat, lng: dispositivo.lng}}
              onClick={openModalGT} 
              title={dispositivo.id}>
              </MarkerF>
            ))}
        </GoogleMap>
      </div>
    )
  } else <>Cargando...</>
}


// import { Wrapper } from "@googlemaps/react-wrapper";
// import { useRef, useEffect, useState } from "react";
// import { createRoot } from "react-dom/client";

// export function GoogleMapComponent() {
//   return (
//     <Wrapper
//       apiKey={process.env.REACT_APP_GOOGLEMAP_APIKEY}
//       version="beta"
//       libraries={["marker"]}
//     >
//       <MyMap />
//     </Wrapper>
//   );
// }

// const mapOptions = {
//   center: { lat: 43.66293, lng: -79.39314 },
//   zoom: 10,
//   disableDefaultUI: true,
// };

// function MyMap() {
//   const [map, setMap] = useState();
//   const ref = useRef();

//   useEffect(() => {
//     setMap(new window.google.maps.Map(ref.current, mapOptions));
//   }, []);

//   return (
//     <>
//       <div ref={ref} id="map" />
//       {map && <Weather map={map} />}
//     </>
//   );
// }

// const weatherData = {
//   A: {
//     name: "Toronto",
//     position: { lat: 43.66293, lng: -79.39314 },
//     climate: "Raining",
//     temp: 20,
//     fiveDay: [15, 18, 12, 22, 20],
//   },
//   B: {
//     name: "Guelph",
//     position: { lat: 43.544811, lng: -80.248108 },
//     climate: "Cloudy",
//     temp: 20,
//     fiveDay: [15, 18, 12, 22, 20],
//   },
//   C: {
//     name: "Orangeville",
//     position: { lat: 43.919239, lng: -80.097412 },
//     climate: "Sunny",
//     temp: 20,
//     fiveDay: [15, 18, 12, 22, 20],
//   },
// };

// function Weather({ map }) {
//   const [data, setData] = useState(weatherData);
//   const [highlight, setHighlight] = useState();
//   const [editing, setEditing] = useState();

//   return (
//     <>
//       {editing && (
//         <Editing
//           weather={data[editing]}
//           update={(newWeather) => {
//             setData((existing) => {
//               return { ...existing, [editing]: { ...newWeather } };
//             });
//           }}
//           close={() => setEditing(null)}
//         />
//       )}
//       {Object.entries(data).map(([key, weather]) => (
//         <Marker
//           key={key}
//           map={map}
//           position={weather.position}
//           onClick={() => setEditing(key)}
//         >
//           <div
//             className={`marker ${weather.climate.toLowerCase()} ${
//               highlight === key || editing === key ? "highlight" : ""
//             }`}
//             onMouseEnter={() => setHighlight(key)}
//             onMouseLeave={() => setHighlight(null)}
//           >
//             <h2>{weather.climate}</h2>
//             <div>{weather.temp}c</div>
//             {highlight === key || editing === key ? (
//               <div className="five-day">
//                 <p>Next 5</p>
//                 <p>{weather.fiveDay.join(", ")}</p>
//               </div>
//             ) : null}
//           </div>
//         </Marker>
//       ))}
//     </>
//   );
// }

// function Editing({ weather, update, close }) {
//   return (
//     <div className="editing">
//       <h2>Editing {weather.name}</h2>

//       <label htmlFor="climate">Climate</label>
//       <select
//         id="climate"
//         value={weather.climate}
//         onChange={(e) => update({ ...weather, climate: e.target.value })}
//       >
//         {["Sunny", "Cloudy", "Raining"].map((val) => (
//           <option key={val} value={val}>
//             {val}
//           </option>
//         ))}
//       </select>

//       <label htmlFor="temp">Temperature</label>
//       <input
//         id="temp"
//         type="number"
//         value={weather.temp}
//         onChange={(e) => update({ ...weather, temp: e.target.value })}
//       />

//       <button type="button" onClick={() => close()}>
//         Close
//       </button>
//     </div>
//   );
// }

// function Marker({ map, position, children, onClick }) {
//   const rootRef = useRef();
//   const markerRef = useRef();

//   useEffect(() => {
//     if (!rootRef.current) {
//       const container = document.createElement("div");
//       rootRef.current = createRoot(container);

//       markerRef.current = new google.maps.marker.AdvancedMarkerView({
//         position,
//         content: container,
//       });
//     }

//     return () => (markerRef.current.map = null);
//   }, []);

//   useEffect(() => {
//     rootRef.current.render(children);
//     markerRef.current.position = position;
//     markerRef.current.map = map;
//     const listener = markerRef.current.addListener("click", onClick);
//     return () => listener.remove();
//   }, [map, position, children, onClick]);
// }