import { Button } from "react-bootstrap"
import "../../css/style.css"

export const ModalProgramarOnOff = ({children, isOpen, closeModal}) => {

  const handleModalContainerClick = e => e.stopPropagation()
  
  return (
    <article className={`modal-programar-on-off ${isOpen && "is-open"}`} onClick={closeModal}>
        <div className="modal-container-programar-on-off" onClick={handleModalContainerClick}>
            <Button variant="transparent" className="modal-close-programar-on-off" onClick={closeModal}>X</Button>
            {children}
        </div>
    </article>
  )
}