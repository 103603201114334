import React from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input } from 'reactstrap'
import { Navbar } from "react-bootstrap";
import logoLoRo from "../img/Proyecto-LoRo-2.png"
import { createUserWithEmailAndPassword, signOut, sendPasswordResetEmail, onAuthStateChanged } from "firebase/auth";
import { addSensor, addZona, auth, auth2, getSensores, getUser, getZonas, registerNewUser } from '../firebase/firebase'
import { useNavigate } from "react-router-dom";
import "../css/style.css"
import { useState, useEffect } from "react";
import Settings from "../img/settings.png"
import { useModalAddSensor } from './modalAddSensor/UseModalAddSensor';
import { ModalAddSensor } from './modalAddSensor/ModalAddSensor';
import { Button, Table } from 'react-bootstrap';
import { useModalAddZona } from './modalAddZona/UseModalAddZona';
import { ModalAddZona } from './modalAddZona/ModalAddZona';

export default function Header() {

    const navigate = useNavigate();
    const [dropdown, setDropdown] = useState(false)
    const [isOpenModalAddSensor, openModalAddSensor, closeModalAddSensor] = useModalAddSensor(false) 
    const [isOpenModalAddZona, openModalAddZona, closeModalAddZona] = useModalAddZona(false) 
    const [user, setUser] = useState(null)

    const [latitud, setLatitud] = useState(0.0)
    const [longitud, setLongitud] = useState(0.0)
    const [zona, setZona] = useState(0.0)
    const [idSensor, setIdSensor] = useState(null)
    const [idZona, setIdZona] = useState(null)
    const [encendido, setEncendido] = useState(true)
    const [nombreZona, setNombreZona] = useState("")

    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
          if (user) {
            const userDB = await getUser(user.uid);
            setUser(userDB);
    
            getSensoresAndZonas()
    
          }
        });
      }, [navigate]);

    async function getSensoresAndZonas() {
        const sensoresAux = await getSensores()
        const zonasAux = await getZonas()
        setIdSensor(sensoresAux.length+1)
        setIdZona(zonasAux.length+1)
    }

    function onChangeDropdown() {
        setDropdown(!dropdown);
    }

    function logout() {
        signOut(auth)
          .then(() => {
            // console.log("OUT");
            navigate("/login");
          })
          .catch((error) => {
            // console.error(error);
            // console.log("error logout");
          });
    }

    async function addSensorToBBDD() {
        const sensor = {
            id: `id${idSensor}`,
            lat: latitud,
            lng: longitud,
            zona: zona
        }
        if (encendido) sensor.power = true
        else sensor.power = false

        await addSensor(sensor);
        window.location.reload()
    }

    async function addZonaToBBDD() {
        const zona = {
            id: `${idZona}`,
            nombre: nombreZona,
        }

        await addZona(zona);
        window.location.reload()
    }

    function onChangePower(e) {
        if (e.target.value === "true") setEncendido(false)
        if (e.target.value === "false") setEncendido(true)
    }

    function navigateAdmin() {
        if (user.admin === true) navigate("/admin")
        else alert("No tienes permisos")
    }

    function navigateZonas() {
        navigate("/")
    }

    function navigateSobre() {
        navigate("/proyecto")
    }

    if (idSensor !== null && idZona !== null) {
        return (
            <>
                <Navbar className="bg-color my-0">
                    <div className='navbar-left my-1 mx-3'>
                        <a href="./">
                            <img src={logoLoRo}
                                    width="55"
                                    height="90"
                                    alt="logo"
                                    className="mx-4"
                                    />
                        </a>
                    </div>
    
                    <span className='mx-auto text-black'><h3><b>LoRo</b></h3></span>
                    
                    <div className='navbar-right'>
                        <Dropdown isOpen={dropdown} toggle={onChangeDropdown} className="my-1 mx-4">
                            <DropdownToggle className="bg-info" caret>
                            <img src={Settings}
                                width="20"
                                height="20"
                                />
                                &nbsp;
                            </DropdownToggle>
    
                            <DropdownMenu>
                                <DropdownItem onClick={openModalAddSensor}>Añadir nuevo sensor</DropdownItem>
                                <DropdownItem onClick={openModalAddZona}>Añadir nueva zona</DropdownItem>
                                <DropdownItem onClick={navigateAdmin}>Gestión de usuarios</DropdownItem>
                                <DropdownItem onClick={navigateZonas}>Gestión de zonas</DropdownItem>
                                <DropdownItem onClick={navigateSobre}>Sobre el proyecto</DropdownItem>
                                <DropdownItem onClick={logout}>Cerrar sesion</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>   
    
                        <ModalAddSensor isOpen={isOpenModalAddSensor} closeModal={closeModalAddSensor}>
                        <h2><b>Añadir nuevo sensor</b></h2>
                            <hr />
                            <h3 className="pt-2">Datos del sensor</h3>
                            <Table striped>
                                <tbody>
                                    <tr>
                                        <td className='p-1'><label><span className="text-danger"></span>Id Sensor</label></td>
                                        <td className='p-1'><Input disabled={true} defaultValue={`id${idSensor}`} /></td>
                                    </tr>
                                    <tr>
                                        <td className='p-1'><label>Latitud</label></td>
                                        <td className='p-1'><Input defaultValue={latitud} onChange={(e) => {setLatitud(e.target.value)}} /></td>
                                    </tr>
                                    <tr>
                                        <td className='p-1'><label>Longitud</label></td>
                                        <td className='p-1'><Input defaultValue={longitud} onChange={(e) => {setLongitud(e.target.value)}} /></td>
                                    </tr>
                                    <tr>
                                        <td className='p-1'><label>Zona</label></td>
                                        <td className='p-1'><Input defaultValue={zona} onChange={(e) => {setZona(e.target.value)}} /></td>
                                    </tr>
                                    <tr>
                                        <div><input type="checkbox" checked={encendido} value={encendido} onChange={onChangePower}/> Encendido</div>
                                    </tr>
                                </tbody>
                            </Table>
                            <div className='text-right'>
                                <Button className="btn-danger mr-1" onClick={closeModalAddSensor}>Cancelar</Button>
                                <Button onClick={addSensorToBBDD} >Aceptar</Button>
                            </div>
                        </ModalAddSensor>

                        <ModalAddZona isOpen={isOpenModalAddZona} closeModal={closeModalAddZona}>
                        <h2><b>Añadir nueva zona</b></h2>
                            <hr />
                            <h3 className="pt-2">Datos de la zona</h3>
                            <Table striped>
                                <tbody>
                                    <tr>
                                        <td className='p-1'><label><span className="text-danger"></span>Id Zona</label></td>
                                        <td className='p-1'><Input disabled={true} defaultValue={`${idZona}`} /></td>
                                    </tr>
                                    <tr>
                                        <td className='p-1'><label>Nombre</label></td>
                                        <td className='p-1'><Input defaultValue={nombreZona} onChange={(e) => {setNombreZona(e.target.value)}} /></td>
                                    </tr>
                                </tbody>
                            </Table>
                            <div className='text-right'>
                                <Button className="btn-danger mr-1" onClick={closeModalAddZona}>Cancelar</Button>
                                <Button onClick={addZonaToBBDD} >Aceptar</Button>
                            </div>
                        </ModalAddZona>
                    </div>
                </Navbar>
            </>
        )
    } else <>Cargando...</>
    
}
