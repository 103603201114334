import react from "react"
import { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap';
import "../css/style.css"
import { updateSensor } from "../firebase/firebase";
import { ModalsGraficaTemperatura } from './modalGraficaTemperatura/ModalsGraficaTemperatura';
import { ModalsProgramarOnOff } from "./modalProgramarOnOff/ModalsProgramarOnOff";

export default function Sensor({ dispositivo, index }) {

  const [power, setPower] = useState(dispositivo.power)

  useEffect(() => {
    setPower(dispositivo.power)
  }, [])

  async function apagarDispositivo() {
    dispositivo.power = false
    await updateSensor(dispositivo)
    setPower(false)
  }

  async function encenderDispositivo() {
    dispositivo.power = true
    await updateSensor(dispositivo)
    setPower(true)
  }

  async function programarEncendidoApagado() {
    
  }
  
  if (dispositivo.power === true) {
    return (
      <>
      
        <div key={index} className="col-lg-2 col-md-6 col-sm-6 con-xs-12 py-3 px-3 d-fill text-center">
          <div className="card radiuscard">
              <div className="card-body bgcolor-success">
                <h4 className="card-title">Dispositivo: {dispositivo.id}</h4>
                <h5>Temperatura: {dispositivo.temp}ºC</h5>
                <h5>Humedad: {dispositivo.humi}%</h5>
                <h5>Estado: <span className="text-success">Encendido</span></h5>
                <ModalsGraficaTemperatura dispositivo={dispositivo} />
                <Button className="mt-1 mx-1 btn-danger" onClick={apagarDispositivo}>Apagar</Button>
                <ModalsProgramarOnOff sensor={dispositivo} />
                <div className='text-secondary mt-1'>Actualizado<br /> {dispositivo.fecha}-{dispositivo.hora}</div>
                <div className="my-auto"></div>
              </div>
          </div>
        </div>
      </>
    )
  }
  else {
    return (
      <>
        <div key={index} className="col-lg-2 col-md-6 col-sm-6 con-xs-12 py-3 px-3 d-fill text-center">
          <div className="card radiuscard">
            <div className="card-body bgcolor-danger">
              <h4 className="card-title">Dispositivo: {dispositivo.id}</h4>
              <h5>Temperatura: {dispositivo.temp}ºC</h5>
              <h5>Humedad: {dispositivo.humi}%</h5>
              <h5>Estado: <span className="text-danger">Apagado</span></h5>
              <ModalsGraficaTemperatura />
              <Button className="mt-1 mx-1 btn-success" onClick={encenderDispositivo}>Encender</Button>
              <Button className="mt-1 mx-1 btn-secondary" onClick={programarEncendidoApagado}>Programar on/off</Button>
              <div className='text-secondary mt-1'>Actualizado<br /> {dispositivo.fecha}-{dispositivo.hora}</div>
              <div className="my-auto"></div>
            </div>
          </div>
        </div>
      </>
    )
  }
}