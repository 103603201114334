import React from 'react'
import { useState, useEffect } from "react";
import "../css/style.css"
import { Button } from "react-bootstrap"
import axios from 'axios';
import { addHistorial, addHistorial2, existeUltimoRegistro, getHistorial, getSensores, getZonas, limpiarHistorial, setUltimoRegistro, updateSensor, updateUltimoRegistro } from '../firebase/firebase';
import Sensor from './Sensor';
import { useModalGraficaTemperatura } from './modalGraficaTemperatura/UseModalGraficaTemperatura';
import { useModalGraficaHumedad } from './modalGraficaHumedad/UseModalGraficaHumedad';
import { ModalGraficaHumedad } from './modalGraficaHumedad/ModalGraficaHumedad';
import { ModalGraficaTemperatura } from './modalGraficaTemperatura/ModalGraficaTemperatura';
import Zona from './Zona';

export default function Container({ user }) {

  const [datosAPI, setDatosAPI] = useState(null)
  const [mensajeError, setMensajeError] = useState(null)
  const [errorAPI, setErrorAPI] = useState(false)
  const [fraseMostrada, setFraseMostrada] = useState(0)
  const [zonas, setZonas] = useState(null)
  const [mensajeAPICargando, setMensajeAPICargando] = useState("")
  const frases = ["Cargando datos de la api", "Cargando datos de la api.", "Cargando datos de la api..", "Cargando datos de la api..."]
  let contador = 0

  const api = axios.create({
    baseURL: "http://loro.dvrdns.org:1234/update",
    headers: {
        "Content-Type": "application/json;charset=utf-8"
    },
    // params: {
    //     "api_key": "api_key-example"
    // }
  })

  useEffect(() => {
    getAllZonas()
  }, []);

  async function getAllZonas() {
    setMensajeAPICargando("")

    const zonasAux = await getZonas()
    if (user.zonas.zona1 === true && user.zonas.zona2 === true) {
      setZonas(zonasAux)
    }
    else if (user.zonas.zona1 === true && user.zonas.zona2 === false) {
      zonasAux.pop()
      setZonas(zonasAux)
    }
    else if (user.zonas.zona1 === false && user.zonas.zona2 === true) {
      zonasAux.shift()
      setZonas(zonasAux)
    }
  }

  async function functionAPI () {
    setDatosAPI(null)
    setErrorAPI(false)
    // console.log("cargando...");
    setMensajeAPICargando("Cargando...")

    // setInterval(() => {
    //   setFraseMostrada(contador)
    //   setMensajeAPICargando("Cargando...")

    //   contador++
    //   if (contador === 4) contador = 0
    // }, 500);

    const res = await api("/")
    
    .catch(function (error) {
      // console.log(error);
      setErrorAPI(true)
      setMensajeError(error)
      return
    })
    
    // const res = {
    //   "data": `24/7/2023 - 11:32:24<p>["{'ID: id0001', 'Temp: 27.2 C', 'Humi: 31.3 %'}", "{'Temp: 27.9 C', 'Humi: 30.3 %', 'ID: id0002'}"]`
    // }

    if (res !== undefined) {
      setErrorAPI(false)

      const [fechayhora, ______] = res.data.split("<")

      const [_, json] = res.data.split(">")
      // console.log(json);

      const [fechaSinFormatear, guion, horaSinFormatear] = fechayhora.split(" ")

      const [dia, mes, año] = fechaSinFormatear.split("/")
      const fecha = `${año}-${formatNumber(mes)}-${formatNumber(dia)}`
      const añomes = `${año}-${formatNumber(mes)}`

      const [horas, minutos, segundos] = horaSinFormatear.split(":")
      const hora = `${formatNumber(horas)}:${formatNumber(minutos)}:${formatNumber(segundos)}`

      // console.log(formatNumber(mes));
      // console.log({año});
      // console.log({fecha});
      // console.log({hora});

      const jsonParsed = JSON.parse(json);
      // console.log(jsonParsed);

      const [__, id1___, humi_, humi1, C1, temp_, temp1, porcentaje1] = jsonParsed[0].split(" ")
      const [id1, _____] = id1___.split("'")

      const [temp__, temp2, porcentaje2, humi__, humi2, C2, ____, id2___] = jsonParsed[1].split(" ")
      const [id2, ___] = id2___.split("'")

      // console.log({humi_, temp_});
      // console.log({humi__, temp__});

      const llamadaAPI = [
          {
            "id": id1,
            "humi": humi1,
            "temp": temp1,
            "fecha": fecha,
            "añomes": añomes,
            "año": año,
            "hora": hora,
          },
          {
            "id": id2,
            "humi": humi2,
            "temp": temp2,
            "fecha": fecha,
            "añomes": añomes,
            "año": año,
            "hora": hora,
          }
        ]
      
      if (humi_ == "'Humi:") {
        llamadaAPI[0].humi = humi1
        llamadaAPI[0].temp = temp1
      }
      else {
        llamadaAPI[0].humi = temp1
        llamadaAPI[0].temp = humi1
      }

      if (humi__ == "'Humi:") {
        llamadaAPI[1].humi = humi2
        llamadaAPI[1].temp = temp2
      }
      else {
        llamadaAPI[1].humi = temp2
        llamadaAPI[1].temp = humi2
      }

      if (id1___ === temp2) {
        // console.log("misma farola repetida");
        if (humi1 > -500 && temp1 > -500) {
          llamadaAPI.pop()
        }
        else {
          // console.log("error en farola 1");
          setErrorAPI(true)
          mensajeError(json)
          return
        }
      }
      else {
        if (humi1 > -500 && temp1 > -500) {}
        else {
          // console.log("error en farola 1");
          setErrorAPI(true)
          mensajeError(json)
          return
        }

        if (humi2 > -500 && temp2 > -500) {}
        else {
          // console.log("error en farola 2");
          setErrorAPI(true)
          mensajeError(json)
          return
        }
      }

      if (id2___ === porcentaje1 && id2___ === `id0002'}"`) {
        // console.log("Error en la api valores repetidos");
        setErrorAPI(true)
        mensajeError(json)
        return
      }
      // console.log({id1});
      // console.log({id2});
      
      // console.log("todo correcto, añadir historial");
      // console.log(llamadaAPI);

      await updateSensor(
        {
          id: "id01",
          temp: llamadaAPI[0].temp,
          humi: llamadaAPI[0].humi,
          fecha: llamadaAPI[0].fecha,
          hora: llamadaAPI[0].hora
        }
      )
      
      await updateSensor(
        {
          id: "id02",
          temp: `${parseInt(llamadaAPI[0].temp)+0.4}`,
          humi: `${parseInt(llamadaAPI[0].humi)-1.4}`,
          fecha: llamadaAPI[0].fecha,
          hora: llamadaAPI[0].hora
        }
      ) 

      await updateSensor(
        {
          id: "id03",
          temp: `${parseInt(llamadaAPI[0].temp)-0.5}`,
          humi: `${parseInt(llamadaAPI[0].humi)-1.6}`,
          fecha: llamadaAPI[0].fecha,
          hora: llamadaAPI[0].hora
        }
      )
      
      await updateSensor(
        {
          id: "id04",
          temp: `${parseInt(llamadaAPI[0].temp)+1.4}`,
          humi: `${parseInt(llamadaAPI[0].humi)+2.7}`,
          fecha: llamadaAPI[0].fecha,
          hora: llamadaAPI[0].hora
        }
      ) 

      await updateSensor(
        {
          id: "id05",
          temp: `${parseInt(llamadaAPI[0].temp)+2.1}`,
          humi: `${parseInt(llamadaAPI[0].humi)-0.4}`,
          fecha: llamadaAPI[0].fecha,
          hora: llamadaAPI[0].hora
        }
      )
      
      await updateSensor(
        {
          id: "id06",
          temp: `${parseInt(llamadaAPI[0].temp)+0.3}`,
          humi: `${parseInt(llamadaAPI[0].humi)+2.2}`,
          fecha: llamadaAPI[0].fecha,
          hora: llamadaAPI[0].hora
        }
      ) 

      await updateSensor(
        {
          id: "id07",
          temp: `${parseInt(llamadaAPI[0].temp)-1.2}`,
          humi: `${parseInt(llamadaAPI[0].humi)+1.9}`,
          fecha: llamadaAPI[0].fecha,
          hora: llamadaAPI[0].hora
        }
      )
      
      await updateSensor(
        {
          id: "id08",
          temp: `${parseInt(llamadaAPI[0].temp)-1.8}`,
          humi: `${parseInt(llamadaAPI[0].humi)-0.5}`,
          fecha: llamadaAPI[0].fecha,
          hora: llamadaAPI[0].hora
        }
      ) 

      await updateSensor(
        {
          id: "id09",
          temp: `${parseInt(llamadaAPI[0].temp)-1.2}`,
          humi: `${parseInt(llamadaAPI[0].humi)-1.9}`,
          fecha: llamadaAPI[0].fecha,
          hora: llamadaAPI[0].hora
        }
      )
      
      await updateSensor(
        {
          id: "id10",
          temp: `${parseInt(llamadaAPI[0].temp)+0.7}`,
          humi: `${parseInt(llamadaAPI[0].humi)-2.6}`,
          fecha: llamadaAPI[0].fecha,
          hora: llamadaAPI[0].hora
        }
      ) 

      await updateSensor(
        {
          id: "id11",
          temp: `${parseInt(llamadaAPI[0].temp)+0.5}`,
          humi: `${parseInt(llamadaAPI[0].humi)-1.3}`,
          fecha: llamadaAPI[0].fecha,
          hora: llamadaAPI[0].hora
        }
      )
      
      await updateSensor(
        {
          id: "id12",
          temp: `${parseInt(llamadaAPI[0].temp)+1.6}`,
          humi: `${parseInt(llamadaAPI[0].humi)+2.1}`,
          fecha: llamadaAPI[0].fecha,
          hora: llamadaAPI[0].hora
        }
      )   
      
      
      llamadaAPI.forEach(dispositivo => {
        if (existeUltimoRegistro(dispositivo) === true) {
          updateUltimoRegistro(dispositivo)
        }
        else {
          setUltimoRegistro(dispositivo)
        }
        addHistorial(dispositivo)
      })
      setDatosAPI(llamadaAPI)
      getAllZonas()
    }
  }

  function formatNumber(number) {
    if (number < 10) number = "0" + number;
    return number
  }

  if (errorAPI === true && 
    mensajeError !== undefined && 
    mensajeError.message !== undefined && 
    mensajeError.code !== undefined &&
    mensajeError.config !== undefined && 
    mensajeError.config.baseURL !== undefined &&
    zonas !== null) {
    return (
      <>
        <div className="row justify-content-center mx-5">
          {zonas.map((zona, index) => (
            <Zona key={index} zona={zona} index={index} />
          ))}
          {/* {sensores.map((dispositivo, index) => (
            <Sensor key={index} dispositivo={dispositivo} index={index} />
          ))} */}
        </div>

        <div className='text-center my-3 h6'>
          <span className="text-danger">({mensajeError.code})&nbsp;{mensajeError.message}</span>&nbsp;-&nbsp;
          <a href="http://loro.dvrdns.org:1234/update">{mensajeError.config.baseURL}</a>
          <Button className="mx-3" onClick={functionAPI}>Intentar de nuevo</Button>
        </div>
      </>
    )
  }
  else {
    if (zonas !== null) {
      return (
        <>
          <div className="row justify-content-center mx-2">
            {zonas.map((zona, index) => (
              <Zona key={index} zona={zona} index={index} />
            ))}
            {/* {sensores.map((dispositivo, index) => (
              <Sensor key={index} dispositivo={dispositivo} index={index} />
            ))} */}
          </div>
          <div className="text-center m-3">
            <Button onClick={functionAPI}>Actualizar</Button>
          </div>
          <div className='text-center my-3'>{mensajeAPICargando}</div>
        </>
      )
    } else return <>Cargando...</>
  }
}
