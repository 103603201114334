import react from "react"
import { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap';
import "../css/style.css"
import { encenderSensoresPorZonas, getSensoresPorZonas, updateSensor } from "../firebase/firebase";
import { ModalsGraficaHumedad } from './modalGraficaHumedad/ModalsGraficaHumedad';
import { ModalsGraficaTemperatura } from './modalGraficaTemperatura/ModalsGraficaTemperatura';
import Sensor from "./Sensor";
import { ModalsProgramarOnOff } from "./modalProgramarOnOff/ModalsProgramarOnOff";

export default function Zona({ zona, index }) {

  const [sensores, setSensores] = useState(null)
  const [contador, setContador] = useState(0)
  let contadorAux = 0

  useEffect(() => {
    getAllSensoresPorZona()
  }, [])

  async function getAllSensoresPorZona() {
    const sensoresAux = await getSensoresPorZonas(zona.id)
    setSensores(sensoresAux)
  }

  function apagarZona() {
    // console.log(sensores);
    // dispositivo.power = false
    sensores.forEach( sensor => {
      sensor.power = false
      updateSensorAux(sensor)
    })
    // setPower(false)
  }

  function encenderZona() {
    // console.log(sensores);
    // dispositivo.power = true
    sensores.forEach( sensor => {
      sensor.power = true
      updateSensorAux(sensor)
    })
    
    
    // setPower(true)
  }

  if (sensores !== null && contadorAux === sensores.length) {
    getAllSensoresPorZona()
    contadorAux = 0
  }

  async function updateSensorAux(sensor) {
    await updateSensor(sensor)
    contadorAux++
    setContador(contadorAux)
  }

  async function programarEncendidoApagado() {

  }

  if (sensores !== null) {
    return (
        <>
            <div key={index} className="col-lg-12 pt-4 px-3 d-fill text-center">
              <div className="card radiuscard">
                  <div className="card-body bg-color-zona">
                    <h4 className="card-title">Zona: {zona.id} ({zona.nombre})</h4>
                    <div className="row justify-content-center">
                      {sensores.map((dispositivo, index) => (
                        <Sensor key={index} dispositivo={dispositivo} index={index} />
                      ))}
                    </div>
                    <Button className="my-2 mx-1 btn-success" onClick={encenderZona}>Encender Zona</Button>
                    <ModalsProgramarOnOff zona={zona} />
                    <Button className="my-2 mx-1 btn-danger" onClick={apagarZona}>Apagar Zona</Button>
                  </div>
              </div>
          </div>
        </>
    )
  } else {return(<>Cargando...</>);}
}
  